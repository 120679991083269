import { BaseScreen, BaseGrid, BaseToolbar, ITableColumn, ICrud, CPF_MASK } from "react-epharma-components/lib";
import React from "react";
import Helpers from "../../helpers";
import Search from "./Search";
import CrudForm from "./Crud";
import { ExportTypes } from "react-epharma-components/lib/components/export";
import NotificationTargetType from "../../enums/NotificationTargetType";

const NotificationRequestScreen: React.FC = (props) => {
  const ColumnsTableGrid: ITableColumn[] = [
    {
      name: "title",
      displayName: "Título",
      sortable: false,
    },
    {
      name: "body",
      displayName: "Mensagem",
      sortable: false,
    },
    {
      name: "targetType",
      displayName: "Enviado para",
      onRender: (value) => NotificationTargetType[value],
    },
    {
      name: "createdAt",
      displayName: "Data do envio",
      type: "datetime",
    },
    {
      name: "author",
      displayName: "Envio por",
    },
  ];

  const crud: ICrud = {
    form: <CrudForm />,
    create: {
      show: true,
    },
    read: {
      show: true,
    },
    exportGrid: {
      show: false,
      types: [ExportTypes.csv],
    },
  };

  return (
    <BaseScreen title="Envio de mensagens Push Notification" description="">
      <BaseGrid
        name="recipe"
        baseEndpoint={Helpers.endpoints.appNotifications}
        toolbar={
          <BaseToolbar
            crud={crud}
            search={{
              show: true,
              form: <Search />,
            }}
          />
        }
        columns={ColumnsTableGrid}
        {...props}
      />
    </BaseScreen>
  );
};
export default NotificationRequestScreen;
