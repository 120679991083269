import React from "react";
import { Row, Col } from "react-bootstrap";
import { BaseInput, BaseForm, BaseFieldSet, BaseSelect, FilterOperator } from "react-epharma-components";

const Search: React.FC = (props) => {
  const id = React.createRef<BaseInput>();
  const author = React.createRef<BaseInput>();
  const initialDate = React.createRef<BaseInput>();
  const finalDate = React.createRef<BaseInput>();
  const notificationTypeValue = React.createRef<BaseSelect>();
  const targetTypeValue = React.createRef<BaseSelect>();

  return (
    <BaseForm refs={[id, author, initialDate, finalDate, notificationTypeValue, targetTypeValue]} {...props}>
      <BaseFieldSet legend="Filtros">
        <Row>
          <Col xs="4" sm="4" md="4" lg="4" xl="4">
            <BaseInput label="Id" name="_id" id="id" ref={id} />
          </Col>
          <Col xs="3" sm="3" md="3" lg="3" xl="3">
            <BaseSelect
              label="Tipo de Mensagem"
              name="notificationType"
              id="notificationType"
              ref={notificationTypeValue}
              options={[
                {
                  value: "0",
                  label: "Padrão",
                },
                {
                  value: "1",
                  label: "Texto Grande",
                },
                {
                  value: "2",
                  label: "Com Imagens",
                },
              ]}
            />
          </Col>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <BaseInput label="Data inicial" name="createdAt" id="initialDate" ref={initialDate} type={"date"} filterOperator={FilterOperator.GreatOrEqualsThan} />
          </Col>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <BaseInput label="Data final" name="createdAt" id="finalDate" ref={finalDate} type={"date"} filterOperator={FilterOperator.LessOrEqualsThan} />
          </Col>
        </Row>
        <Row>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <BaseInput label="Envio por" name="author" id="author" ref={author} maxLength={100} />
          </Col>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <BaseSelect
              label="Enviado para"
              name="targetType"
              id="targetType"
              ref={targetTypeValue}
              options={[
                {
                  value: "0",
                  label: "Todos os usuários",
                },
                {
                  value: "1",
                  label: "Beneficiário(s)",
                },
                {
                  value: "2",
                  label: "Plano(s)",
                },
              ]}
            />
          </Col>
        </Row>
      </BaseFieldSet>
    </BaseForm>
  );
};
export default Search;
