import React, { FunctionComponent } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import NotificationRequestScreen from "./pages/notificationRequest";

const Routes: FunctionComponent = () => (
  <Router>
    <Route exact path="/notificationRequest" component={NotificationRequestScreen} />
  </Router>
);

export default Routes;
