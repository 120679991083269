import { DEFAULT_CURRENCY_MASK } from "react-epharma-components/lib/index";
import { createNumberMask } from "text-mask-addons";

const DECIMAL_CURRENCY_MASK_CFG = {
  ...DEFAULT_CURRENCY_MASK,
};

const decimalMask = createNumberMask(DECIMAL_CURRENCY_MASK_CFG);
const toDecimal = (value: any) => {
  const regex = new RegExp(/\./);
  const decimalSymbol = DECIMAL_CURRENCY_MASK_CFG.decimalSymbol;
  let val = value + "";

  if (val.indexOf(".") > -1) {
    const beforeDecimals = val.substr(0, val.indexOf("."));
    const afterDecimals = val.substr(val.indexOf(".") + 1).padEnd(DECIMAL_CURRENCY_MASK_CFG.decimalLimit || 2, "0");

    val = beforeDecimals + decimalSymbol + afterDecimals;
  } else {
    val = val + "," + "0".padEnd(DECIMAL_CURRENCY_MASK_CFG.decimalLimit || 2, "0");
  }
  return val;
};

const { appService } = window;

const endpoints = {
  appNotifications: appService.base + appService.appNotifications,
};

export default {
  endpoints,
  decimalMask,
  toDecimal,
};
